// //
// // carousel
// //

.carousel {
  &:hover {
    .tns-controls button {
      opacity: 1;

      &[data-controls="prev"] {
        left: rem(16);
      }

      &[data-controls="next"] {
        right: rem(16);
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .tns-controls button {
      opacity: 1;
    }
  }

  &[class*="-visible"] {
    .tns-ovh {
      overflow: visible !important;
    }
  }

  &[class*="-with-nav"] {
    margin-bottom: rem(56);

    .tns-nav {
      position: absolute;
      bottom: rem(-56);
      width: 100%;
    }
  }

  &[class*="-label-controls"] {
    .tns-controls {
      position: absolute;
      right: 0;
      bottom: 148px;

      button {
        position: relative;
        width: rem(80);
        height: rem(80);
        background: $teal;
        font-size: rem(24);
        margin-top: rem(-40);
        border-radius: 0;
        opacity: 1;

        &[data-controls="prev"] {
          left: 0;
        }

        &[data-controls="next"] {
          right: 0;
        }

        &:hover {
          background: darken($teal, 10%);
        }

        &:active {
          background: darken($teal, 10%);
        }
      }
    }

    .tns-slide-active .carousel-custom-label {
      opacity: 1;
    }

    .carousel-custom-label {
      margin-top: 48px;
      height: 100px;
      opacity: 0.5;
      transition: opacity 0.3s ease;
    }
  }

  &[class*="-big-controls"] {
    .tns-controls {
      position: absolute;
      right: 0;
      bottom: 0;

      button {
        position: relative;
        width: rem(80);
        height: rem(80);
        background: $teal;
        font-size: rem(24);
        margin-top: rem(-40);
        border-radius: 0;
        opacity: 1;

        &[data-controls="prev"] {
          left: 0;
        }

        &[data-controls="next"] {
          right: 0;
        }

        &:hover {
          background: darken($teal, 10%);
        }

        &:active {
          background: darken($teal, 10%);
        }
      }
    }

    .tns-slide-active .carousel-custom-label {
      opacity: 1;
    }

    .carousel-custom-label {
      margin-top: 48px;
      height: 100px;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  &[class*="-align"] {
    [data-carousel] {
      display: flex;
      align-items: center;
    }
  }
}

// modificators
.carousel-steps {
  cursor: grab;

  .tns-item {
    @include nofocus;
    user-select: none;
    position: relative;
    padding-top: rem(96);
    counter-increment: slider;

    &::before {
      transition: all 0.2s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      content: counter(slider, decimal);
      display: flex;
      width: rem(64);
      height: rem(64);
      align-items: center;
      justify-content: center;
      font-size: $font-size-lg;
      background: $black;
      color: $white;
      border-radius: 50%;
      z-index: 4;
    }

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        top: rem(30);
        left: rem(72);
        right: rem(8);
        height: rem(2);
        display: flex;
        background: $border-color;
      }
    }

    &.tns-slide-active {
      &::before {
        background: var(--bs-purple);
      }
    }
  }
}

.carousel-thumbs {
  >div {
    @include nofocus;
    opacity: 0.25;
    max-width: 100%;
    cursor: pointer;

    &.tns-nav-active {
      opacity: 1;
    }
  }
}

.tns-thumbs {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: $border-width solid rgba($black, 0.2);

  [data-nav] {
    @include nofocus;

    .tns-thumbs-item {
      opacity: 0.5;
    }

    &.tns-nav-active .tns-thumbs-item {
      &::before {
        background-color: $white;
      }

      opacity: 1;
    }
  }

  .tns-thumbs-item {
    position: relative;
    display: block;
    position: relative;
    cursor: pointer;

    &::before {
      position: absolute;
      top: -$border-width;
      left: 0;
      right: 0;
      display: flex;
      content: "";
      height: $border-width;
    }
  }
}

// item
.tns-item {
  img {
    max-width: 100%;
  }
}

// controls
.tns-controls {
  @include nofocus;
  display: flex;
  justify-content: space-between;

  button {
    transition: all 0.2s;
    border: 0;
    display: flex;
    width: rem(56);
    height: rem(56);
    padding: 0;
    text-align: center;
    font-size: rem(20);
    color: $black;
    border-radius: 50%;
    background: rgba($black, 0.4);
    opacity: 0;

    position: absolute;
    top: 50%;
    z-index: 4;
    margin-top: rem(-24);

    .bi {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1em;
      height: 1em;
      margin: -0.5em 0 0 -0.5em;
      color: $black;
    }

    &:hover {
      background: $black;
    }

    &[disabled] {
      opacity: 0.2;
    }

    &[data-controls="prev"] {
      left: rem(24);
    }

    &[data-controls="next"] {
      right: rem(24);
    }
  }
}

.white-arrows .tns-controls button {
  color: white;
}

.white-arrows .tns-controls button .bi {
  color: white;
}

// nav
.tns-nav {
  @include nofocus;
  display: flex;
  justify-content: center;
  margin-top: rem(24);
  padding: 0;
  list-style: none;

  button {
    @include nofocus;
    position: relative;
    cursor: pointer;
    padding: 0;
    width: rem(24);
    height: rem(24);
    border: 0;
    border-radius: 50%;
    background: transparent;

    &::before {
      @include transition;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: rem(8);
      height: rem(8);
      margin-top: rem(-4);
      margin-left: rem(-4);
      background: rgba($black, 0.2);
      border-radius: 50%;
    }

    &.tns-nav-active::before,
    &.tns-nav-active:hover::before {
      background: $black;
    }

    &:hover::before {
      background: rgba($black, 0.4);
    }
  }
}

.card-body-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-labels {
  display: flex;
  flex-direction: column;
}

.card-button {
  margin-left: auto;
}