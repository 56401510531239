//
// buttons
//

// colors
@each $color, $value in $colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// text + icon btn
.btn-with-icon {
  position: relative;
  padding-right: $btn-padding-x * 2;
  text-align: left;
  transition: transform 0.3s ease; 
  transform: translateZ(0);

  .bi {
    @include translate(0, -50%);
    position: absolute;
    top: 50%;
    right: $btn-padding-x;
  }

  &.btn-sm {
    padding-right: $btn-padding-x-sm * 2;

    .bi {
      right: $btn-padding-x-sm;
    }
  }

  &.btn-lg {
    padding-right: $btn-padding-x-lg * 2;

    .bi {
      right: $btn-padding-x-lg;
    }
  }
}


.btn-with-icon:hover {
  transform: scale(1.05) translateY(-5px);
}

// icon btn
.btn-icon {
  position: relative;
  box-sizing: content-box;
  width: 1.5em;
  height: 1.5em;
  padding: $btn-padding-y;

  &[class*="-sm"] {
    padding: $btn-padding-y-sm;
  }

  &[class*="-lg"] {
    padding: $btn-padding-y-lg;
  }

  &[class*="-xl"] {
    padding: $btn-padding-y-lg * 1.5;
    font-size: rem(24);
  }

  .bi {
    @include translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.btn img {
  width: auto;
  height: rem(32);
}

.btn-white:hover {
  background-color: $gray-100;
}

.btn-outline-light {
  color: $black;
}

.btn-filter {
  @include nofocus;
  display: inline-block;
  border: $border-width solid $border-color;
  color: $secondary;

  &:not(:last-child) {
    margin-right: rem(4);
  }

  &:hover {
    border-color: $black;
  }

  &.current {
    background-color: $black;
    border-color: $black;
    color: $white;
  }
}


/* Base styling for the pop-up-hover effect */
.pop-up-hover {
  display: inline-block;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
  transform: translateZ(0); /* Enhance 3D performance */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Initial subtle shadow */
}

/* When hovering, scale the element up slightly and adjust the shadow to give a lifted effect */
.pop-up-hover:hover {
  transform: scale(1.05); /* Slightly larger to create a 'pop' effect */
  box-shadow: 0 10px 20px rgba(0,0,0,0.2); /* Larger shadow to simulate elevation */
}

.pop-up-hover-no-shadow {
  display: inline-block;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
  transform: translateZ(0); /* Enhance 3D performance */
}

/* When hovering, scale the element up slightly and adjust the shadow to give a lifted effect */
.pop-up-hover-no-shadow:hover {
  transform: scale(1.05); /* Slightly larger to create a 'pop' effect */
}

.btn-no-padding {
  padding: 0;
}