//
// typography
//

// spacing fix
p,
ul,
ol,
dl,
h1,
h2,
h3,
h4,
h5,
h6 {
  &:last-child {
    margin-bottom: 0;
  }
}

blockquote {
  margin-bottom: 0;
}

a {
  @include transition;
}

code {
  padding: rem(4) rem(8);
  background: $gray-200;
  color: $black;
  font-weight: $font-weight-bold;
}

// eyebrow
%eyebrow {
  display: inline-block;
  font-size: rem(12);
  font-family: $headings-font-family;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.eyebrow {
  @extend %eyebrow;
}

// underline
%underline {
  @include nofocus;
  display: inline-block;
  position: relative;

  &::before {
    @include transition(0.4s, width, 0s);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    border-bottom: $border-width solid currentColor;
  }

  &:hover {
    text-decoration: none;

    &::before {
      width: 100%;
    }
  }
}

.underline {
  @extend %underline;
}

// action link style
%action {
  @include transition;
  display: inline-block;
  position: relative;
  padding-right: 2em;
  cursor: pointer;

  .bi {
    @include transition;
    @include translateY(-50%);
    position: absolute;
    right: 0.5em;
    top: 50%;
    display: block;
  }

  &:hover {
    .bi {
      right: 0;
    }
  }
}

.action {
  @extend %action;
}

// list inline separated
.list-inline-separated {
  .list-inline-item {
    position: relative;
  }

  .list-inline-item:not(:last-child) {
    margin: 0;

    &::after {
      content: "/";
      margin: 0 rem(8);
    }
  }
}

// text shadow
.text-shadow {
  text-shadow: 0 0 32px rgba(0, 0, 0, 0.5);
}

hr.or {
  position: relative;
  background-color: $border-color;

  &::before {
    transform: translate(-50%, -50%);
    position: absolute;
    content: "or";
    left: 50%;
    top: 50%;
    background: $white;
    padding: 0 rem(16);
  }
}

.add-semi-colon-to-end {

  &::after {
    content: ";";
    font-weight: 600;
    color: $purple;
  }
}

.add-semi-colon-to-end-teal {
  position: inline;

  &::after {
    content: ";";
    font-weight: 600;
    color: $teal;
  }
}

// shine
.shine {
  overflow: hidden;
  background: linear-gradient(90deg, rgba($white, 0), $white, rgba($white, 0));
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 2s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }

  100% {
    background-position: 500%;
  }
}


@font-face {
  font-family: 'archia';
  src: url('/src/fonts/Archia-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'archia';
  src: url('/src/fonts/Archia-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'archia';
  src: url('/src/fonts/Archia-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

body {
  font-family: 'archia', sans-serif;
}

/* Base state for elements before animation starts */
[data-aos="slide-right"] {
  visibility: hidden;
  clip-path: inset(0 100% 0 0); /* Hide the element */
  transition: clip-path 0.5s ease-out, visibility 0s linear 0.5s; /* Animate clip-path and delay visibility change */
}

/* State for elements when they are animated */
[data-aos].aos-animate[data-aos="slide-right"] {
  visibility: visible;
  clip-path: inset(0 0 0 0); /* Reveal the element */
  transition: clip-path 0.5s ease-out; /* Only animate clip-path */
}

.highlight {
  background-color: $purple;
  color: white;
  padding: 2px 2px;
  display: inline-block;
}

p .highlight {
  background-color: $teal;
  color: black;
  padding: 2px 2px;
  display: inline-block;
}

.highlight-hover {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  margin-right: 10px
}

.highlight-hover:hover {
  color: white !important;
  transition: color 0.5s ease-out
}

.highlight-hover::before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background-color: $purple;
  transition: right 0.5s ease-out;
  z-index: -1;
}

.highlight-hover:hover::before {
  right: 0;
}

